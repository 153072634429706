































































































































































































































































































































































































import {
  defineComponent,
  PropType,
  ref,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import { Menu } from '@gemini-vsf/api-client';
import { useUiHelpers } from '~/composables';
import { RightArrow, MenuClose, MenuBack } from '~/components/General/Icons';
import dataManipulation from '~/helpers/dataManipulation';
import HeaderNavigationItem from './HeaderNavigationItem.vue';

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationItem,
    RightArrow,
    MenuClose,
    MenuBack,
  },
  data() {
    return {
      subMenuIcon: ['customer-service.svg', 'find-us.svg', 'logo-colfert.png'],
    };
  },
  props: {
    menu: {
      type: Object as PropType<Menu>,
      default: () => ({
        items: [],
      }),
    },
    subHeaderMenu: {
      type: Object as PropType<Menu>,
      default: () => ({
        items: [],
      }),
    },
    subMenu: {
      type: Object as PropType<Menu>,
      default: () => ({
        items: [],
      }),
    },
  },
  setup() {
    const { localePath } = useContext();
    const { getCatLink } = useUiHelpers();
    const { wait } = dataManipulation();
    const router = useRouter();

    const currentMenuItem = ref(null);
    const subMenuItem = ref(null);
    const subNextMenuItem = ref(null);
    const level = ref(1);
    const lvl0ItemRefs = ref();
    const hasFocus = ref(false);
    const firstActiveItem = ref();
    const secondActiveItem = ref();
    const thirdActiveItem = ref();
    const firstActiveItemR = ref();
    const secondActiveItemR = ref();
    const thirdActiveItemR = ref();
    const focusedElement = ref();
    const wantsToOpenMenu = ref(false);

    const setCurrentMenuItem = (menuItem) => {
      if (!menuItem) {
        firstActiveItem.value = null;
        secondActiveItem.value = null;
        thirdActiveItem.value = null;
        firstActiveItemR.value = null;
        secondActiveItemR.value = null;
        thirdActiveItemR.value = null;
      }
      wantsToOpenMenu.value = false;
      currentMenuItem.value = menuItem;
    };

    const hasChildren = (menuItem) => {
      return Boolean(menuItem?.children?.length);
    };
    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement.value = event.target;
      hasFocus.value = true;
    };

    const focusRootElementAndHideSubcategories = () => {
      setCurrentMenuItem(null);
      if (focusedElement.value !== null) focusedElement.value.focus();
    };

    const onMouseEnter = (menuItem, index, lvl: number, menuSide = 'left') => {
      wantsToOpenMenu.value = true;
      // eslint-disable-next-line default-case
      switch (lvl) {
        case 1: {
          if (!wantsToOpenMenu.value) {
            wantsToOpenMenu.value = false;
            return;
          }
          firstActiveItem.value = null;
          secondActiveItem.value = null;
          thirdActiveItem.value = null;
          firstActiveItemR.value = null;
          secondActiveItemR.value = null;
          thirdActiveItemR.value = null;
          subMenuItem.value = null;
          subNextMenuItem.value = null;
          if (menuSide === 'right') {
            firstActiveItemR.value = index;
            firstActiveItem.value = null;
          } else {
            firstActiveItem.value = index;
            firstActiveItemR.value = null;
          }
          currentMenuItem.value = menuItem;

          break;
        }
        case 2: {
          subNextMenuItem.value = null;
          if (menuSide === 'right') {
            secondActiveItemR.value = index;
            secondActiveItem.value = null;
          } else {
            secondActiveItem.value = index;
            secondActiveItemR.value = null;
          }
          thirdActiveItemR.value = null;
          thirdActiveItem.value = null;
          subMenuItem.value = menuItem;

          break;
        }
        case 3: {
          if (menuSide === 'right') {
            thirdActiveItemR.value = index;
            secondActiveItem.value = null;
          } else {
            thirdActiveItem.value = index;
            thirdActiveItemR.value = null;
          }
          subNextMenuItem.value = menuItem;

          break;
        }
        // No default
      }
      level.value = lvl + 1;
      focusedElement.value = null;
      hasFocus.value = false;
    };

    const getSubMenu = (menuItem) => {
      currentMenuItem.value = menuItem;
      focusedElement.value = null;
      hasFocus.value = false;
    };

    const changeLevel = (lev) => {
      level.value = lev;
    };
    const removeFilters = () => {
      const bodyEle = document.querySelectorAll('html');
      bodyEle.length > 0 && bodyEle[0].classList.remove('hamburger-open');
      level.value = 1;
      currentMenuItem.value = [];
      subMenuItem.value = [];
      subNextMenuItem.value = [];
    };
    const closeFilters = () => {
      removeFilters();
    };
    const hasMenu = (menu) => {
      if (
        menu &&
        (menu.label === 'Tutti i Prodotti' || menu.label === 'All the products')
      ) {
        return true;
      }
      return false;
    };
    const openPage = async (menuItem) => {
      if (menuItem.label.toLowerCase() === 'tutti i prodotti') {
        onMouseEnter(menuItem, 0, 1);
      } else {
        await router.push(localePath(menuItem.link));
        closeFilters();
      }
    };

    return {
      getCatLink,
      setCurrentMenuItem,
      currentMenuItem,
      hasChildren,
      setFocus,
      focusRootElementAndHideSubcategories,
      lvl0ItemRefs,
      hasFocus,
      onMouseEnter,
      getSubMenu,
      subMenuItem,
      subNextMenuItem,
      level,
      changeLevel,
      closeFilters,
      removeFilters,
      firstActiveItem,
      secondActiveItem,
      thirdActiveItem,
      firstActiveItemR,
      secondActiveItemR,
      thirdActiveItemR,
      hasMenu,
      openPage,
    };
  },
});
